import { ReactElement } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ACCESS_FLAGS, CAPABILITY_INTENT, SENTRY_TAG_PLATFORM } from 'constants/config';
import { ROUTE_KEYS } from 'constants/routeConfig';
import { useAppSelector } from 'hooks/toolkit';
import DashboardLayout from 'layout/dashboard-layout';
import { RootState } from 'store';
import { NextPageWithLayout } from 'types';
import { Loader } from 'components/loader/Loader';

const Home: NextPageWithLayout = () => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizbankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];
  const isPayoutsEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_PAYOUTS_ENABLED];
  const isTreasuryEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_TREASURY_ENABLED];

  Sentry.setTag(
    SENTRY_TAG_PLATFORM,
    isBizbankingEnabled
      ? CAPABILITY_INTENT.BIZ_BANKING
      : isPayoutsEnabled
      ? CAPABILITY_INTENT.PAYOUTS
      : isTreasuryEnabled
      ? CAPABILITY_INTENT.TREASURY
      : ''
  );

  return (
    <div className='tw-w-full tw-h-body tw-flex tw-items-center tw-justify-center'>
      <Loader />
    </div>
  );
};

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <div>
      <DashboardLayout page={ROUTE_KEYS.ROOT}>{page}</DashboardLayout>
    </div>
  );
};

export default Home;
